import React from 'react';
import Head from '../components/head';
import Layout from '../components/layout';
import { Col, Grid, Row } from 'react-flexbox-grid';
import ProductCarousel from '../components/product-carousel';

const NotFound = () => {
  return (
    <Layout>
      <div className="bottom-margin">
        <Grid>
          <Row>
            <Col xs={12} className="centered-content">
              <Head title={'404 Not found'}/>
              <h1>Sorry, that page can’t be found.</h1>
              <p>But while you’re here, check out some of our innovative products designed to make healthcare easier.</p>
            </Col>
          </Row>


          <Row>
            <Col xs={12}>
              <ProductCarousel />  
            </Col>
          </Row>
        </Grid>
      </div>
    </Layout>
  );
};

export default NotFound;
