import { graphql, PageProps } from 'gatsby';
import React from 'react';
import CustomContent from '../components/custom-content';
import Head from '../components/head';
import Layout from '../components/layout';
import NotFound from '../pages/404';
import PageGrid from './components-template/page-grid';
import PageList from './components-template/page-list';
import PageProcess from './components-template/page-process';

export const pageListData = graphql`
  query ($sectionPath: String!) {
    contentfulSection(sectionPath: {eq: $sectionPath}) {
      sectionName
      sectionPath
      page {
        ... on ContentfulCustomPage {
          internalName
          header
          customContent {
            json
          }
        }
      }
    }
  }
`;

type DataProps = {
  contentfulSection: {
    sectionName: string;
    sectionPath: string;
    page: {
      internalName: string
      header: string
      customContent: {
        json: JSON
      }
    }
  };
};

const Product: React.FC<PageProps<DataProps>> = ({ data, path }) => {
  const Section = ({ sectionName }: any) => {
    if (sectionName === 'section-builder') return <NotFound />
    if (sectionName === 'outreach') return <PageList />
    if (sectionName === 'work') return <PageGrid />
    if (sectionName === 'process') return <PageProcess />
    return <CustomContent data={data.contentfulSection.page} />
  }

  return (
    <Layout>
      <Head title={data.contentfulSection.sectionName} />
      <Section sectionName={data.contentfulSection.sectionPath} />
    </Layout>
  );
};

export default Product;
