import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import processStyles from './process-item.module.scss';

const ProcessItem = ({ data }: any) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean,
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid>
      <Row>
        <Col xs={12} md={5}>
          <h2>{data.header}</h2>
          <p>{data.paragraph?.paragraph}</p>
        </Col>
        <Col xs={12} md={6} mdOffset={1}>
          <img
            className="edge-right"
            src={data.photo.file.url}
            alt={data.photo.file.fileName}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div>
            <h2>{data.detailsHeader}</h2>
            <div className={processStyles.root}>
              {data.expandableItems.map((item: any) => (
                <Accordion
                  key={item.id}
                  expanded={expanded === item.id}
                  onChange={handleChange(item.id)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={item.id}
                    id={item.id}
                  >
                    <Typography className={processStyles.title}>
                      {item?.title ?? ''}
                    </Typography>
                    <Typography className={processStyles.summary}>
                      {item?.subtitle ?? ''}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={processStyles.body}>
                      {item?.body?.body ?? ''}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </Col>
      </Row>

      <div className="divider process" />
    </Grid>
  );
};

export default ProcessItem;
