import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import ProcessItem from '../../components/process-item';
import ProductCarousel from '../../components/product-carousel';
import pageProcessStyles from './page-process.module.scss';
import { StickyContainer, Sticky } from 'react-sticky';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useInView } from 'react-intersection-observer';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const PageProcess = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSection(sectionPath: { eq: "process" }) {
        page {
          ... on ContentfulProcessPage {
            header
            paragraph {
              paragraph
            }
            headerPhoto {
              file {
                fileName
                url
              }
            }
            processes {
              id
              title
              header
              paragraph {
                paragraph
              }
              photo {
                file {
                  fileName
                  url
                }
              }
              detailsHeader
              expandableItems {
                id
                title
                subtitle
                body {
                  body
                }
              }
            }
          }
        }
      }
    }
  `);

  const options = {
    threshold: 0.65,
  };
  const optionsHead = { threshold: 1, triggerOnce: true };

  const [researchRef, researchInView, researchEntry] = useInView(options);
  const [designRef, designInView, designEntry] = useInView(options);
  const [buildRef, buildInView, buildEntry] = useInView(options);
  const [deliverRef, deliverInView, deliverEntry] = useInView(options);

  const [titleRef, titleInView, titleEntry] = useInView(options);
  const [messageRef, messageInView, messageEntry] = useInView(options);
  const [imageRef, imageInView, imageEntry] = useInView(options);

  const [headerbgRef, headerbgInView, headerbgEntry] = useInView(optionsHead);

  const scrollToSection = (sectionName: string) => {
    scrollTo(sectionName);
    trackCustomEvent({
      category: 'Navigation',
      action: 'Click',
      label: `Scroll to section ${sectionName}`,
      value: 43,
    });
  };

  return (
    <div>
      <div className={pageProcessStyles.container}>
        <Grid className="vert-center header-background">
          <Row>
            <Col xs={12} md={6}>
              <div ref={titleRef}>
                <h1
                  className={
                    titleInView
                      ? pageProcessStyles.slideUShow
                      : pageProcessStyles.slideUHide
                  }
                >
                  {data.contentfulSection.page.header}
                </h1>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div ref={messageRef}>
                <p
                  className={
                    messageInView
                      ? pageProcessStyles.fadeShow
                      : pageProcessStyles.fadeHide
                  }
                >
                  {data.contentfulSection.page.paragraph?.paragraph}
                </p>
              </div>
            </Col>
          </Row>

          <div className="overlay" />

          <div ref={headerbgRef}>
            <img
              className={`${
                headerbgInView
                  ? pageProcessStyles.fadeShow
                  : pageProcessStyles.fadeHide
              } bg`}
              src={data.contentfulSection.page.headerPhoto.file.url}
            />
          </div>
        </Grid>

        <Grid className={pageProcessStyles.processWrap}>
          <Row>
            <Col md={4} xl={3} className="mHide">
              <div className={`${pageProcessStyles.menuWrap} margin-top`}>
                <StickyContainer className="grey mHide">
                  <Sticky topOffset={35}>
                    {({ style }) => (
                      <div style={style}>
                        <ul className={pageProcessStyles.listnav}>
                          <li>
                            <a
                              className={
                                researchInView
                                  ? pageProcessStyles.sectionMenuItemActive
                                  : pageProcessStyles.sectionMenuItem
                              }
                              onClick={() => scrollToSection('#Research')}
                            >
                              <span className={pageProcessStyles.listNumber}>
                                01
                              </span>{' '}
                              <span className={pageProcessStyles.listName}>
                                Research
                              </span>
                            </a>
                          </li>

                          <li>
                            <a
                              className={
                                designInView
                                  ? pageProcessStyles.sectionMenuItemActive
                                  : pageProcessStyles.sectionMenuItem
                              }
                              onClick={() => scrollToSection('#Design')}
                            >
                              <span className={pageProcessStyles.listNumber}>
                                02
                              </span>{' '}
                              <span className={pageProcessStyles.listName}>
                                Design
                              </span>
                            </a>
                          </li>

                          <li>
                            <a
                              className={
                                buildInView
                                  ? pageProcessStyles.sectionMenuItemActive
                                  : pageProcessStyles.sectionMenuItem
                              }
                              onClick={() => scrollToSection('#Build')}
                            >
                              <span className={pageProcessStyles.listNumber}>
                                03
                              </span>{' '}
                              <span className={pageProcessStyles.listName}>
                                Build
                              </span>
                            </a>
                          </li>

                          <li>
                            <a
                              className={
                                deliverInView
                                  ? pageProcessStyles.sectionMenuItemActive
                                  : pageProcessStyles.sectionMenuItem
                              }
                              onClick={() => scrollToSection('#Deliver')}
                            >
                              <span className={pageProcessStyles.listNumber}>
                                04
                              </span>{' '}
                              <span className={pageProcessStyles.listName}>
                                Deliver
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </Sticky>
                </StickyContainer>
              </div>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={8}
              xl={9}
              className={pageProcessStyles.processContainer}
            >
              {data.contentfulSection.page.processes.map(
                (process: any, index: number) => {
                  if (index === 0)
                    return (
                      <div id={process.title} ref={researchRef}>
                        <ProcessItem data={process} />
                      </div>
                    );
                  if (index === 1)
                    return (
                      <div id={process.title} ref={designRef}>
                        <ProcessItem data={process} />
                      </div>
                    );
                  if (index === 2)
                    return (
                      <div id={process.title} ref={buildRef}>
                        <ProcessItem data={process} />
                      </div>
                    );
                  if (index === 3)
                    return (
                      <div id={process.title} ref={deliverRef}>
                        <ProcessItem data={process} />
                      </div>
                    );
                },
              )}
            </Col>
          </Row>
        </Grid>

        <Grid>
          <Row>
            <Col md={12}>
              <ProductCarousel />
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
};

export default PageProcess;
