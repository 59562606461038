import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Contact from '../../components/contact';
import { Col, Grid, Row } from 'react-flexbox-grid';
import pageListStyles from './page-list.module.scss';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { useInView } from 'react-intersection-observer';

const PageList = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSection(sectionPath: { eq: "outreach" }) {
        page {
          ... on ContentfulListPage {
            header
            addressPart1
            addressPart2
            addressPart3
            email
            phone
            paragraph {
              paragraph
            }
            listItems {
              button
              id
              eventName
              dateStart(formatString: "MMM D")
              dateEnd(formatString: "MMM D")
              location
              link
            }
          }
        }
      }
    }
  `);

  const options = { threshold: 0.5 };
  const [titleRef, titleInView, titleEntry] = useInView(options);
  const [messageRef, messageInView, messageEntry] = useInView(options);

  return (
    <div>
      <Grid className="yellow vert-center head">
        <Row className="addPad alignContentTop">
          <Col xs={12} md={5}>
            <div ref={titleRef}>
              <div
                className={
                  titleInView
                    ? pageListStyles.slideUShow
                    : pageListStyles.slideUHide
                }
              >
                <h1>{data.contentfulSection.page.paragraph?.paragraph}</h1>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} mdOffset={1}>
            <div className={pageListStyles.contactInfo} ref={messageRef}>
              <div
                className={
                  messageInView
                    ? pageListStyles.fadeShow
                    : pageListStyles.fadeHide
                }
              >
                <p>
                  <a href={`tel:${data.contentfulSection.page.phone}`}>
                    {data.contentfulSection.page.phone}
                  </a>
                </p>

                <p>
                  <a
                    href={`mailto:${data.contentfulSection.page.email}`}
                    target="_blank"
                  >
                    {data.contentfulSection.page.email}
                  </a>
                </p>

                <h2>{data.contentfulSection.page.header}</h2>
                <p>
                  {data.contentfulSection.page.addressPart1}
                  <br />
                  {data.contentfulSection.page.addressPart2}
                  <br />
                  {data.contentfulSection.page.addressPart3}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Grid>

      <Grid className={pageListStyles.pageList}>
        <Row>
          <Col>
            <h1 className="margin-bot">Upcoming Events</h1>
          </Col>
        </Row>
        {data.contentfulSection.page.listItems.map((item: any) => (
          <div key={item.id} className={pageListStyles.itemWrap}>
            <Row className={pageListStyles.pageListItem}>
              <Col xs={12} md={2} className="alignTop">
                <h4>{`${item.dateStart} - ${item.dateEnd}`}</h4>
              </Col>
              <Col xs={12} md={6}>
                <h1>{item.eventName}</h1>
                <p>{item.location}</p>
              </Col>
              <Col xs={12} md={3} mdOffset={1}>
                <OutboundLink
                  className="btn arrow"
                  target="_blank"
                  href={item.link}
                >
                  {item.button}
                </OutboundLink>
              </Col>

              <div className="divider grey" />
            </Row>
          </div>
        ))}
      </Grid>
    </div>
  );
};

export default PageList;
