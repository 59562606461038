import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import pageGridStyles from './page-grid.module.scss';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { useInView } from 'react-intersection-observer';

const PageGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSection(sectionPath: { eq: "work" }) {
        page {
          ... on ContentfulGridPage {
            header
            headerAuthor
            paragraph {
              paragraph
            }
            backgroundImage {
              file {
                url
                fileName
              }
            }
            products {
              id
              name
              model
              productPath
              image {
                file {
                  fileName
                  url
                }
              }
              id
            }
            posts {
              title
              image {
                file {
                  url
                  fileName
                }
              }
              link
            }
          }
        }
      }
    }
  `);

  const options = {
    threshold: 0.5,
  };
  const optionsHead = { threshold: 1, triggerOnce: true };

  const [titleRef, titleInView, titleEntry] = useInView(options);
  const [messageRef, messageInView, messageEntry] = useInView(options);
  const [headerbgRef, headerbgInView, headerbgEntry] = useInView(optionsHead);

  return (
    <div className={pageGridStyles.work}>
      <Grid className="vert-center header-background">
        <Row>
          <Col xs={12} md={6}>
            <div ref={titleRef}>
              <div
                className={`${
                  titleInView
                    ? pageGridStyles.slideUShow
                    : pageGridStyles.slideUHide
                } quoteWrap`}
              >
                <div className="symbol alignTop">
                  <span />
                </div>

                <div className="quote">
                  <h1>{data.contentfulSection.page.header}</h1>
                  <h3>{data.contentfulSection.page.headerAuthor}</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div ref={messageRef}>
              <p
                className={
                  messageInView
                    ? pageGridStyles.fadeShow
                    : pageGridStyles.fadeHide
                }
              >
                {data.contentfulSection.page.paragraph?.paragraph}
              </p>
            </div>
          </Col>
        </Row>

        <div className="overlay" />

        <div ref={headerbgRef}>
          <img
            className={`${
              headerbgInView ? pageGridStyles.fadeShow : pageGridStyles.fadeHide
            } bg`}
            src={data.contentfulSection.page.backgroundImage.file.url}
          />
        </div>
      </Grid>

      <Grid>
        <Row>
          <Col xs={12}>
            <h3>PRODUCTS</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className={pageGridStyles.productGrid}>
            <Row className={pageGridStyles.gridWrap}>
              {data.contentfulSection.page.products.map((product: any) => (
                <Col
                  md={4}
                  key={product.id}
                  className={pageGridStyles.gridItem}
                >
                  <Link to={`/product/${product.productPath}`}>
                    <img
                      src={product?.image?.file?.url ?? ''}
                      alt={product?.image?.file?.fileName ?? ''}
                      width="200"
                    />
                    <p>
                      <strong>{product.name}</strong> {product.model}
                    </p>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Grid>

      <Grid>
        <Row>
          <Col xs={12}>
            <h3>THOUGHT PIECES</h3>
          </Col>
        </Row>

        <Row className={pageGridStyles.medium}>
          <Col xs={12} md={4} className={pageGridStyles.medium}>
            <div className={pageGridStyles.imgWrap}>
              <OutboundLink
                href={data?.contentfulSection?.page?.posts[0]?.link ?? ''}
                className={pageGridStyles.medium}
                target="_blank"
              >
                <img
                  src={
                    data?.contentfulSection?.page?.posts[0]?.image?.file?.url ??
                    ''
                  }
                  alt={
                    data?.contentfulSection?.page?.posts[0]?.image?.file
                      ?.fileName ?? ''
                  }
                />
              </OutboundLink>
            </div>

            <p>
              <strong>
                <OutboundLink
                  href={data?.contentfulSection?.page?.posts[0]?.link ?? ''}
                  className={pageGridStyles.medium}
                  target="_blank"
                >
                  {data?.contentfulSection?.page?.posts[0]?.title ?? ''}
                </OutboundLink>
              </strong>
            </p>
          </Col>

          <Col xs={12} md={4} className={pageGridStyles.medium}>
            <div className={pageGridStyles.imgWrap}>
              <OutboundLink
                href={data?.contentfulSection?.page?.posts[1]?.link ?? ''}
                className={pageGridStyles.medium}
                target="_blank"
              >
                <img
                  src={
                    data?.contentfulSection?.page?.posts[1]?.image?.file?.url ??
                    ''
                  }
                  alt={
                    data?.contentfulSection?.page?.posts[1]?.image?.file
                      ?.fileName ?? ''
                  }
                />
              </OutboundLink>
            </div>

            <p>
              <strong>
                <OutboundLink
                  href={data?.contentfulSection?.page?.posts[1]?.link ?? ''}
                  className={pageGridStyles.medium}
                  target="_blank"
                >
                  {data?.contentfulSection?.page?.posts[1]?.title ?? ''}
                </OutboundLink>
              </strong>
            </p>
          </Col>

          <Col xs={12} md={4} className={pageGridStyles.medium}>
            <div className={pageGridStyles.imgWrap}>
              <OutboundLink
                href={data?.contentfulSection?.page?.posts[2]?.link ?? ''}
                className={pageGridStyles.medium}
                target="_blank"
              >
                <img
                  src={
                    data?.contentfulSection?.page?.posts[2]?.image?.file?.url ??
                    ''
                  }
                  alt={
                    data?.contentfulSection?.page?.posts[2]?.image?.file
                      ?.fileName ?? ''
                  }
                />
              </OutboundLink>
            </div>
            <p>
              <strong>
                <OutboundLink
                  href={data?.contentfulSection?.page?.posts[2]?.link ?? ''}
                  className={pageGridStyles.medium}
                  target="_blank"
                >
                  {data?.contentfulSection?.page?.posts[2]?.title ?? ''}
                </OutboundLink>
              </strong>
            </p>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default PageGrid;
